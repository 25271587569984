var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
    [
      _c(
        "c-mobile-table",
        {
          ref: "grid",
          attrs: {
            title: `5S 점검결과 목록`,
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.deptData.results,
            usePaging: false,
            isExcelDown: false,
            filtering: false,
            columnSetting: false,
            isTitle: true,
            gridHeightAuto: true,
            editable: _vm.editable && !_vm.disabled,
            rowKey: "saiPatrolResultId",
            selection: "multiple",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "link" && _vm.isApproveComplete
                    ? [
                        _c("q-btn", {
                          attrs: {
                            push: "",
                            size: "xs",
                            icon: "search",
                            color: "blue-6",
                            round: "",
                            dense: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.linkImprDetail(props.row)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-header-append" },
            [
              _c("c-select", {
                attrs: {
                  edtable: _vm.editable,
                  comboItems: _vm.deptItems,
                  type: "edit",
                  itemText: "deptName",
                  itemValue: "deptCd",
                },
                on: { datachange: _vm.setResults },
                model: {
                  value: _vm.deptData.deptCd,
                  callback: function ($$v) {
                    _vm.$set(_vm.deptData, "deptCd", $$v)
                  },
                  expression: "deptData.deptCd",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "prefixContent" },
            [
              _c(
                "font",
                {
                  staticStyle: {
                    "font-size": "0.8em",
                    "font-weight": "300",
                    color: "red",
                  },
                },
                [_vm._v(" 조치내역 ~ 비고란은 개선 메뉴에서 입력 바랍니다. ")]
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { showLoading: false, label: "", icon: "add" },
                        on: { btnClicked: _vm.addResult },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.deptData.results.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeResult },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }