<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <c-mobile-table
      ref="grid"
      :title="`5S 점검결과 목록`"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="deptData.results"
      :usePaging="false"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      :isTitle="true"
      :gridHeightAuto="true"
      :editable="editable && !disabled"
      rowKey="saiPatrolResultId"
      selection="multiple"
    >
      <template slot="table-header-append">
        <c-select
          :edtable="editable"
          :comboItems="deptItems"
          type="edit"
          itemText="deptName"
          itemValue="deptCd"
          v-model="deptData.deptCd"
          @datachange="setResults"
        />
      </template>
      <template slot="prefixContent">
        <font style="font-size:0.8em;font-weight:300;color:red">
          조치내역 ~ 비고란은 개선 메뉴에서 입력 바랍니다.
        </font>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='link' && isApproveComplete">
            <q-btn push size="xs" icon="search" color="blue-6" round dense @click="linkImprDetail(props.row)"></q-btn>
        </template>
        <!-- <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&isApproveComplete"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="saiPatrolResultId"
            ibmTaskTypeCd="ITT0000065"
            ibmTaskUnderTypeCd="ITTU000105"
            @imprChange="imprChange"
          />
        </template> -->
      </template>
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn
            v-if="editable && !disabled"
            :showLoading="false"
            label=""
            icon="add"
            @btnClicked="addResult"
          />
          <c-btn
            v-if="editable && !disabled && deptData.results.length > 0"
            :showLoading="false"
            label=""
            icon="remove"
            @btnClicked="removeResult"
          />
        </q-btn-group>
      </template>
    </c-mobile-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import { uid } from 'quasar'
export default {
  name: 'patrolDeptResult',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        isApprContent: false,
      }),
    },
    grid: {
      type: Object,
      default: () => ({
        columns: [],
        height: "360px",
        data: [],
      })
    },
    patrol: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        plantCd: '',
        patrolDate: '',
        patrolName: '',
        patrolResultSummary: '',
        patrolCompleteFlag: '',
        patrolUserIds: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',
        chgUserId: '',
        results: [],
        deleteResults: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      deptUrl: '',
      saveType: 'POST',
      resultItemUrl: '',
      deptData: {
        deptName: '',
        deptCd: '',
        results: [],
      },
      deptItems: [],
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isApproval: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isApproveComplete() {
      return Boolean(this.patrol.approvalStatusCd) && this.patrol.approvalStatusCd === 'ASC9999999'
    },
    isOld() {
      return Boolean(this.popupParam.saiPatrolId)
    },
    disabled() {
      return this.patrol.patrolCompleteFlag === "Y"
        // 결재중인 경우 비활성화
        || (this.patrol.approvalStatusCd === 'ASC0000001' && !this.popupParam.isApprContent)
    },
    // // 개선요청 내용 전달
    // requestContentsCols() {
    //   return ['patrolItemName'];
    // },
    // // 개선요청 Component
    // imprComponent() {
    //   return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    // },
    // [E] 결재관련 버튼 컨트롤
  },
  watch: {
    patrol() {
      this.setResults();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.deptUrl = selectConfig.mdm.dept.list.url;

      this.getDeptList();
    },
    getDeptList() {
      this.$http.url = this.deptUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        fivesFlag: 'Y',
      };
      this.$http.request((_result) => {
        this.deptItems = this.$_.clone(_result.data);

        this.$set(this.deptData, 'deptCd', this.$store.getters.user.deptCd); 

        this.setResults();
      },);
    },
    addResult() {
      this.patrol.results.splice(0, 0, {
        saiPatrolId: this.popupParam.saiPatrolId,
        deptCd: this.deptData.deptCd,
        saiPatrolResultId: uid(),
        mdmLocationId: '',
        locationName: '',
        patrolContents: '',
        patrolRequestDate: this.$comm.getToday(),
        remark: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
      this.setResults();
    },
    setResults() {
      this.deptData.results = this.$_.filter(this.patrol.results, {deptCd: this.deptData.deptCd})
    },
    removeResult() {
      let selectData = this.$refs["grid"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.patrol.deleteResults) this.patrol.deleteResults = [];
          if (this.$_.findIndex(this.patrol.deleteResults, {
                saiPatrolResultId: item.saiPatrolResultId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.patrol.deleteResults.push(item);
          }
          this.patrol.results = this.$_.reject(this.patrol.results, item);
          this.deptData.results = this.$_.reject(this.deptData.results, item);
        });
      }
    },
    // [E]] 결재관련 
    // 개선창 닫기 후
    // imprChange() {
    //   this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
    // },
    linkImprDetail(row) {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      // this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit("getDetail")
    },
  }
};
</script>
